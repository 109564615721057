import { createContext, useEffect, useState } from "react";
import io from "socket.io-client";

export const WebSocketContext = createContext();

const WebSocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("TOKEN"));
  useEffect(() => {
    const newSocket = io("https://expressgroupe.artspace.support");
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContextProvider;
